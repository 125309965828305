import { LOAD_GAMES_TABLE, LOAD_GAMES_TABLE_ERROR, LOAD_GAMES_TABLE_SUCCESSFUL } from '../actions/types';
import convert from 'xml-js';

const initialState = {
  data: {},
  loading: false,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GAMES_TABLE:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case LOAD_GAMES_TABLE_SUCCESSFUL:
      

      //console.log("gamesTable reducer A ccc", tempData);

      let tempData =  Object.assign({}, state.data);
      let jsPayload = convert.xml2js(action.payload, {compact: true, spaces: 4});
      tempData[action.id] = jsPayload;


      //console.log("gamesTable reducer ccc", tempData);

      return {
        ...state,
        data: tempData,
        loading: false
      };
    case LOAD_GAMES_TABLE_ERROR:
      //console.log("gamesTable reducer ERROR ccc", state);
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
