import { LOAD_CALENDAR, LOAD_CALENDAR_ERROR, LOAD_CALENDAR_SUCCESSFUL } from '../actions/types';


const initialState = {
  events: [],
  loading: false,
  error: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CALENDAR:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      };
    case LOAD_CALENDAR_SUCCESSFUL:
      //console.log("LOAD_CALENDAR_SUCCESSFUL", action.payload);
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case LOAD_CALENDAR_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return { ...state };
  }
};
